import axios from "axios";

export const useTestApi = () => {
    const testApi = async() => {
        try{
            axios({
                method: 'Get',
                url: 'https://eloncryptofx-api.onrender.com'
            })
        } catch(error){
            console.log(error)
        }
    } 
    return { testApi }
}