import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import NavbarComponent from './components/navbar';
import Dashboard from './pages/user/dashboard';
import FundAccount from './pages/user/fund';
import Withdraw from './pages/user/withdrawal';
import History from './pages/user/history';
import GeneralNavbar from './components/navbarH/index'
import LandingPage from './pages/Landing';
import Login from './pages/user/login';
import Signup from './pages/user/signup';
import AdminDashboard from './pages/admin/dashboard';
import Blocked from './pages/user/blocked';

import Test from './pages/test';
import AdminNavbarComponent from './components/adminNavbar';
import AdminUsers from './pages/admin/users';
import AdminUser from './pages/admin/users/components/user';
import AdminMail from './pages/admin/email';
import AdminLogin from './pages/admin/log';

import { useAuthContext } from './hooks/useAuthcontext';
import { UseGetUserDetails } from './hooks/useGetUserDetails';
import { useEffect, useState, useRef } from 'react';
import { useTestApi } from './hooks/useTestApi';

// import {  } from 

function App() {  
  const { user } = useAuthContext()
  const { getData, role } =  UseGetUserDetails()
  const { testApi } = useTestApi()

  useEffect(() => {
    console.log(user)
    if(user !== null){
      console.log(user)
      getData(user.uid)
      testApi()
    }else{
      console.log('Hello from the other side')
    }
  }, [user])


  
  return (
    <div className="App">
     <BrowserRouter>
      {(user && role === 'user') && <NavbarComponent/>}
      {(user && role === 'admin') && <AdminNavbarComponent/>}
      {(user === null && !role) && <GeneralNavbar/>}
      <Routes>
        <Route path='/user/dashboard' element={(user && role === 'user') ? <Dashboard/>:<Login/>} />
        <Route path='/user/fund' element={(user && role === 'user') ? <FundAccount/>: <Login/>}/>
        <Route path='/suspended' element={ <Blocked/>}/>
        <Route path='/user/withdraw' element={(user && role === 'user') ? <Withdraw/> : <Login/>}/>
        <Route path='/user/history' element={(user && role === 'user') ? <History/>:<Login/>}/>
        <Route path='/test' element={<Test/>}/>
        <Route path='/' element={(user && role === 'user') ? <Dashboard/> : <LandingPage/>}/>
        <Route path='/login' element={(user && role === 'user') ? <Dashboard/> : <Login/>}/>
        <Route path='/sign_up' element={(user && role === 'user') ? <Dashboard/> : <Signup/>}/>
        <Route path='/admin/dashboard' element={(user && role === 'admin') ? <AdminDashboard/>:<AdminLogin/>}/>
        <Route path='/admin/users' element={(user && role === 'admin') ? <AdminUsers/> : <AdminLogin/>} />
        <Route path='/admin/user/:id' element={(user && role === 'admin') ? <AdminUser/> : <AdminLogin/>} />
        <Route path="/admin/mail/:id" element={(user && role === 'admin') ? <AdminMail/> : <AdminLogin/>}/>
        <Route path='/administrator/login' element={(user && role === 'admin') ? <AdminDashboard/> :<AdminLogin/> }/>
      </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
