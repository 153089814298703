import React from 'react'
import './styles.css'
import { useEffect } from 'react'
import { UsegetAllUsers } from '../../../hooks/useGetAllUsers'
import { useTestApi } from '../../../hooks/useTestApi'

export default function AdminDashboard() {

  const { getUsers, users, numUsers } = UsegetAllUsers()
  const { testApi } = useTestApi()

  useEffect(() => {
    getUsers()
    testApi()
  }, [])

  return (
    <div className='page admin-dashboard-container'>
      <div className="admin-dashboard-wrapper">
        <div className="dashboard-sub-container">
            <div className="dashboard-box">
                <h2>{numUsers}</h2>
                <h2>Users</h2>
            </div>
            <div className="dashboard-box">
                <h2>0</h2>
                <h2>Deposits</h2>
            </div>
        </div>
      </div>
    </div>
  )
};