import { firestore } from "../firebase/config"
import { useState } from "react"
import { useAuthContext } from "./useAuthcontext"

export const UseGetHistory = () => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState('')
    const [ depositList, setDepositList ] = useState([])
    const [ withdrawList, setWithdrawList ] = useState([])
    const { user } = useAuthContext()

    const getHistory = async() => {
        try{
            setError(false)
            setLoading(true)
            const query = [ 'user', "==", user.uid ]
            let ref = await firestore.collection('Deposits').where(...query)
            ref.onSnapshot((snapshot) => {
                let result = []
                snapshot.docs.forEach((doc) => {
                    result.push({ ...doc.data(), id: doc.id})
                    setDepositList(result)
                })
            })
            setError(false)
            setLoading(false)
        } catch(error){
            console.log(error)
            setLoading(false)
            setError(true)
        }
    } 
    return { depositList, getHistory, loading, error,  }
}