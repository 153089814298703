import { useState } from 'react'
import axios from 'axios'
import { useAuthContext } from './useAuthcontext'

export const UseMail = () => {
    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ error, setError ] = useState(false)
    const { user } = useAuthContext()

    const mail = async(email, subject, message) => {
        try{
            setLoading(true)
            setError(false)
            setSuccess(false)
            console.log(email)
            const res = await axios({
                method: "Post",
                url: 'https://eloncryptofx-api.onrender.com/compose',
                data:{
                    email: email,
                    username: user.displayName,
                    subject,
                    message
                }
            })
            if(res.data.status === success){
                setLoading(false)
                setError(false)
                setSuccess(true)
            }
            setLoading(false)
            setError(false)
            setSuccess(true)
        } catch(error){
            setLoading(false)
            setError(false)
            setSuccess(true)
        }
    }  
    return { loading, error, success, mail }
}