import React, { useEffect, useRef } from 'react';
let tvScriptLoadingPromise;
const MarketTracker = () => {
    const onLoadScriptRef = useRef();
//   useEffect(() => {
//       const script = document.createElement('script');
//       script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
//       script.async = true;
  
//       script.innerHTML = JSON.stringify({
//         symbols: [
//           {
//             proName: 'FOREXCOM:SPXUSD',
//             title: 'S&P 500',
//           },
//           {
//             proName: 'FOREXCOM:NSXUSD',
//             title: 'US 100',
//           },
//           {
//             proName: 'FX_IDC:EURUSD',
//             title: 'EUR to USD',
//           },
//           {
//             proName: 'BITSTAMP:BTCUSD',
//             title: 'Bitcoin',
//           },
//           {
//             proName: 'BITSTAMP:ETHUSD',
//             title: 'Ethereum',
//           },
//         ],
//         showSymbolLogo: true,
//         colorTheme: 'light',
//         isTransparent: false,
//         displayMode: 'adaptive',
//         locale: 'en',
//       });
  
//     //   document.querySelector('.tradingview-widget-container__widget').appendChild(script);
//       document.querySelector('.tradingview-widget-container').appendChild(script);
//     // Load the TradingView widget script
//   }, []);

  useEffect(
    () => {
        onLoadScriptRef.current = createWidget;
  
        if (!tvScriptLoadingPromise) {
          tvScriptLoadingPromise = new Promise((resolve) => {
            const script = document.createElement('script');
            // script.id = 'tradingview-widget-loading-script';
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
            script.type = 'text/javascript';
            script.onload = resolve;
  
            document.head.appendChild(script);
          });
        }
  
        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
  
        return () => onLoadScriptRef.current = null;
  
        function createWidget() {
          if (document.getElementById('tradingview-widget-container') && 'TradingView' in window) {
            new window.TradingView.widget({
                symbols: [
                    {
                      proName: 'FOREXCOM:SPXUSD',
                      title: 'S&P 500',
                    },
                    {
                      proName: 'FOREXCOM:NSXUSD',
                      title: 'US 100',
                    },
                    {
                      proName: 'FX_IDC:EURUSD',
                      title: 'EUR to USD',
                    },
                    {
                      proName: 'BITSTAMP:BTCUSD',
                      title: 'Bitcoin',
                    },
                    {
                      proName: 'BITSTAMP:ETHUSD',
                      title: 'Ethereum',
                    },
                  ],
                  showSymbolLogo: true,
                  colorTheme: 'light',
                  isTransparent: false,
                  displayMode: 'adaptive',
                  locale: 'en',
              container_id: "tradingview-widget-container"
            });
          }
        }
      },
      []
    )

  return (
    <div className="tradingview-widget-container market-tracker-container"></div>
  );
};

export default MarketTracker;
