import { database, firestore } from "../firebase/config"
import { useState } from "react"
import { useAuthContext } from "./useAuthcontext"

export const UseGetUserDetails = () => {
    const [ response, setResponse ] = useState([])
    // const { user } = useAuthContext()
    // const  uid = user.uid
    const [ role, setRole ] = useState('')

    const getData = async(id) => {
        try{
            /* await database.child(`User/${id}`).on('value', snapshot => {
                if(snapshot.val() !== null){
                    const data = ({...snapshot.val()})
                    Object.keys(data).map(id => {
                        return setRole(data[id].role)
                    })
                } else {
                    console.log('something went wrong')
                }
            }) */
            const query = [ 'user', "==", id ]
            const ref = await firestore.collection('Users').where(...query)
            ref.onSnapshot((snapshot) => {
                let result = []
                snapshot.docs.forEach((doc) => {
                    result.push({ ...doc.data()})
                })
                setRole(result[0].role)
            })
        } catch(error){
            console.log(error)
        }
    }
    return { getData, response, role }
}