import { database, firestore } from "../firebase/config"
import { useFireStore } from "./useFireStore"
import { useAuthContext } from "./useAuthcontext"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export const useFundAccount = () => {
    const [ loading, setLoading ] = useState(false)
    const [ fundError, setFundError ] = useState('')
    const [ success, setSuccess ] = useState(false)
    const { addDocument } = useFireStore('Deposits')
    const { user } = useAuthContext()
    const navigate = useNavigate()
    const date = new Date();
    let day = date.getDate();
    let year = date.getFullYear();
    let month = date.getMonth() + 1
    const fundAccount = async(amount, walletType) => {
        // console.log(user.uid)
        try{

            setLoading(true)
            addDocument({
                date: `${day}-${month}-${year}`,
                amount,
                type: 'deposit',
                walletType,
                status: 'Pending',
                user: user.uid
            })
            .then(() => navigate('/user/dashboard'))
            // .catch(err => console.log(err))

            // await fitr
            /* await database.child(`Deposits/${user.uid}`).push({
            },
            (error) => {
                if(error){
                    setLoading(false)
                    throw new Error('Could not complete funding try again')
                } else{
                    setLoading(false)
                    setSuccess(true)
                    console.log('Deposit successful')
                    setTimeout(() => {
                        navigate('/user/dashboard')
                    }, 3000)
                }
            }) */
            setLoading(false)
        } catch(error){
            setLoading(false)
            console.log(error)
        }
    }
    return { loading, fundError, fundAccount, success }
}