import { useState } from "react"
import { auth, database, firestore } from "../firebase/config"
import { useAuthContext } from "./useAuthcontext"
import { useNavigate } from "react-router-dom"

export const UseLogin = () => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState('')
    const { dispatch  } = useAuthContext()
    const [ status, setStatus ] = useState('')
    const navigate = useNavigate()
    // const [ success ]

    const login = async(email, password) => {
        try{
            setError('')
            setLoading(true)
            const response = await auth.signInWithEmailAndPassword(email, password)
            console.log(response.user)
            if(!response){
                console.log(response.user)
                setError('Something went wrong')
                // throw new Error('Something went wrong')
            } else{
                // let tStatus = ''
                console.log(response.user.uid)
                const query = [ 'user', "==", response.user.uid ]
                const ref = firestore.collection('Users').where(...query)

                await ref.onSnapshot((snapshot) => {
                    let result = []
                    snapshot.docs.forEach((doc) => {
                        result.push({...doc.data()})
                    })
                    setStatus(result[0].disabled)
                    if(result[0].disabled){
                        navigate('/suspended')
                    }else{
                        dispatch({ type: 'LOGIN', payload: response.user })
                    }
                    // console.log(result[0])
                    
                    // dispatch({ type: 'LOGIN', payload: response.user })
                })


               /*  database.child(`User/${response.user.uid}`).on('value', snapshot => {
                    if(snapshot.val() !== null){
                        const data = ({...snapshot.val()})
                        Object.keys(data).map(id => {
                            console.log(data[id].disabled)
                            if(data[id].disabled){
                                navigate('/suspended')
                            }else{
                                dispatch({ type: 'LOGIN', payload: response.user })
                            }
                            return  setStatus(data[id].disabled)
                        })
                    } else {
                        console.log('something went wrong')
                    }
                }) */
                // console.log(status, tStatus)
                
            }
            setLoading(false)
            setError('')
        } catch(error){
            setLoading(false)
            console.log(error)
            setError('Could not login User try again')
        }
    }
    return { login, loading, error }
}