import { auth } from "../firebase/config"
import { useAuthContext } from "./useAuthcontext"

export const useLogout = () => {
    const { dispatch } = useAuthContext()
    const logout = async() => {
        try{
            await auth.signOut()
            dispatch({ type: 'LOGOUT' })
        } catch(error){
            console.log(error)
        }
    } 
    return { logout }
}