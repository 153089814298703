import React from 'react'
import './styles.css'

export default function Blocked() {
  return (
    <div className='blocked-container'>
      <div className="blocked-wrapper error-container error-center">
        <h2>Your account has been temporary Suspended</h2>
        <h4>For more information talk to our customer service</h4>
      </div>
    </div>
  )
}
