import './styles.css'
import { UseGetHistory } from '../../../hooks/useGetHistory'
import { useState, useRef, useEffect } from 'react'
import { PreLoader1 } from '../../../utils/preloader/preloader1'

export default function History() {
    const container1 = useRef()
    const container2 = useRef()
    const { loading, getHistory, depositList, error } = UseGetHistory()

    const [ showDeposits, setShowDeposits ] = useState(true)
    const [ showWithdraws, setShowWithdraws ] = useState(false)

    const handleShowDeposit = () => {
        setShowDeposits(true)
        setShowWithdraws(false)
        container1.current.classList.add('active')
        container2.current.classList.remove('active')
    }
    const handleShowWithdraw = () => {
        setShowWithdraws(true)
        setShowDeposits(false)
        container1.current.classList.remove('active')
        container2.current.classList.add('active')
    }

    useEffect(() => {
        getHistory()
    }, [])

  return (
    <div className='page history-container'>
      {!loading && <div className="history-wrapper">
        <div className="history-table-nav">
            <div onClick={() => handleShowDeposit()} className="history-table-nav-con history-nav-con1" ref={container1}>
                <h4>Deposits</h4>
            </div>
            <div onClick={() => handleShowWithdraw()} className="history-table-nav-con history-nav-con2" ref={container2}>
                <h4>Withdraws</h4>
            </div>
        </div>
        {showDeposits && <div className="history-table">
            <table>
        <thead>
            <tr>
            <th>Amount</th>
            <th>Payment method</th>
            <th>Status</th>
            <th>Date</th>
            </tr>
        </thead>
        <tbody>
        { depositList.length !== 0 && depositList.map((deposit) => (
                <tr>
                <td>${deposit.amount}</td>
                <td>{deposit.walletType}</td>
                {deposit.status === 'Pending' ? <td className='d-red'>{deposit.status}</td> :<td className='d-green'>{deposit.status}</td>}
                <td>{deposit.date}</td>
                </tr>
            )) }
        </tbody>
        </table>
    </div>}
        {showWithdraws && <div className="history-table">
            <table>
        <thead>
            <tr>
            <th>Amount</th>
            <th>Payment method</th>
            <th>Status</th>
            <th>Date</th>
            </tr>
        </thead>
        <tbody>
        {/* { depositList.length !== 0 && depositList.map((deposit) => (
                <tr>
                <td>{deposit.amount}</td>
                <td>{deposit.walletType}</td>
                {deposit.status === 'Pending' ? <td className='d-red'>{deposit.status}</td> :<td className='d-green'>{deposit.status}</td>}
                <td>{deposit.date}</td>
                </tr>
            )) } */}
        </tbody>
        </table>
    </div>}
    </div>}
    { loading && <PreLoader1/> }
  </div>
  )
}
