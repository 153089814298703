import { database, firestore } from "../firebase/config"
import { useAuthContext } from "./useAuthcontext"
import { useState } from "react"
import { useCollection } from "./useCollection"
// import { ref } from 'firebase/database'
// import {  }

export const UsegetAllUsers = () => {
    const { user } = useAuthContext()
    const [ loading, setLoading ] = useState(false)
    const [ users, setUsers ] = useState([])
    const [ numUsers, setNumUsers ] = useState(0)
    let dt = 0
    let Documents


    const getUsers = async () => {
        try{
            setLoading(true)
            // const query = [ 'user', "==", user.uid ]
            let ref = await firestore.collection('Users')
            await ref.onSnapshot((snapshot) => {
                let result = []
                // result.push(...snapshot.docs.data())
                // console.log(result)
                snapshot.docs.forEach((doc) => {
                    result.push({ ...doc.data(), id: doc.id })
                    setUsers(result)
                    setNumUsers(result.length)
                })
            })

            let Deposit
            
            setLoading(false)
        } catch(error){
            setLoading(false)
            console.log(error)
        }
    }
    return { getUsers, users, numUsers, loading }
}