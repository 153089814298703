import './styles.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuthContext } from '../../../hooks/useAuthcontext'
import { FaDollarSign, FaCoins, FaDownload } from 'react-icons/fa'
import { PreLoader1 } from '../../../utils/preloader/preloader1'
import { UsegetBalance } from '../../../hooks/usegetBalance'
// COMPONENTS
import MarketTracker from './components/marketTracker'
import MarketChart from './components/marketChart'

export default function Dashboard (){
    const { getBalance, slot, loading, balance, deposit, dt } = UsegetBalance()
    const { user } =  useAuthContext()
    const name = user.displayName.split(' ')[0]

    useEffect(() => {
        getBalance()
    },[])

    return (
        <div className="dashboard-container page">
            {!loading && <div className="dashboard-wrapper">
                <div className="dashboard-welcome-text">
                    <h3>Welcome, {name}</h3>
                </div>
                <div className="dashboard-link-button">
                    <Link to='/user/fund'>
                        <button className='dashboard-btn btn1'>Fund your Account</button>
                    </Link>
                    <Link to='/user/fund'>
                        <button className='dashboard-btn btn2'>Withdraw Funds</button>
                    </Link>
                </div>
                <MarketTracker/>
                <div className="dashboard-user-details-container">
                    <div className="dashboard-user-details-wrapper">
                        <div className="details-box">
                            <div className="detail-icon-con icon-con1">
                                <FaDollarSign className='detail-icon'/>
                            </div>
                            <div className="user-detail-text">
                                <h3>${deposit + slot}.00</h3>
                                <h5>Account Balance</h5>
                            </div>
                        </div>
                        <div className="details-box">
                            <div className="detail-icon-con icon-con2">
                                <FaDownload className='detail-icon'/>
                            </div>
                            <div className="user-detail-text">
                                <h3>${deposit}.00</h3>
                                <h5>Total Deposit</h5>
                            </div>
                        </div>
                        <div className="details-box">
                            <div className="detail-icon-con icon-con3">
                                <FaCoins className='detail-icon'/>
                            </div>
                            <div className="user-detail-text">
                                <h3>${slot}.00</h3>
                                <h5>Slot booking</h5>
                            </div>
                        </div>
                        <div className="details-box">
                            <div className="detail-icon-con icon-con4">
                                <FaDollarSign className='detail-icon'/>
                            </div>
                            <div className="user-detail-text">
                                <h3>$0.00</h3>
                                <h5>Total Withdraws</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <MarketChart/>
            </div>}
            { loading && <PreLoader1/> }
        </div>
    )
}