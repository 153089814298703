import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useRef } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useMemo } from 'react'
import { UseSignup } from '../../../hooks/useSignup'
import './styles.css'

export default function Signup() {
  const [ firstName, setFirstName ] = useState('')
  const [ firstNameError, setFirstNameError ] = useState('')
  const [ lastName, setLastName ] = useState('')
  const [ lastNameError, setLastNameError ] = useState('')
  const [ country, setCountry ] = useState('') 
  const [ countryError, setCountryError ] = useState('') 
  const [ Address, setAddress ] = useState('')
  const [ AddressError, setAddressError ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ phoneError, setPhoneError ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ emailError, setEmailError ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ passwordError, setPasswordError ] = useState('')
  const [ passwordConfirm, setPasswordConfirm ] = useState('')
  const [ passwordConfirmError, setPasswordConfirmError ] = useState('')
  const { signup, loading, error, success } = UseSignup()

  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = value => {
    setCountry(value.label)
  }

  const passwordInput = useRef()
  const passwordConfirmInput = useRef()

  const hidePassword = () => {
    if(passwordInput.current.type = 'password'){
      passwordInput.current.type = 'text'
      passwordConfirmInput.current.type = 'text'
    } else{
      passwordInput.current.type = 'password'
      passwordConfirmInput.current.type = 'password'
    }
  }

  const handleSignup = () => {
    setFirstNameError('')
    setLastNameError('')
    setPhoneError('')
    setAddressError('')
    setCountryError('')
    setEmailError('')
    setPasswordError('')
    setPasswordConfirmError('')
    if(!firstName) return setFirstNameError('Input first name')
    if(!lastName) return setFirstNameError('Input last name')
    if(!email) return setEmailError('Input email')
    if(!phone) return setPhoneError('Input phone number')
    if(!Address) return setAddressError('Input address')
    if(!country) return setCountryError('Select Country')
    if(!password) return setPasswordError('Input password')
    if(!passwordConfirm) {
        return setPasswordConfirmError('Input confirm password')
    } else if(password !== passwordConfirm){
        return setPasswordConfirmError('Passwords are incorrect')
    }
    if( firstName && lastName && phone && Address && country && email && password && passwordConfirm){
      signup(
        firstName,
        lastName,
        phone,
        Address,
        country,
        email,
        password,
        passwordConfirm
      )
    }
  }

  return (
    <div className='auth-container'>
      <div className="auth-wrapper">
        <div className="auth-text">
          <h2>Signup</h2>
        </div>
        { error && <div className='error-container error-center'>{error}</div> }
        <div className="auth-form-container">
          <div className="auth-form-sub">
            <label>
              <span>FirstName</span>
              <input type="text" required
               onChange={e => setFirstName(e.target.value)}
               value={firstName}
              />
              {firstNameError && <div className='error-container'>{firstNameError}</div>}
            </label>
            <label>
              <span>LastName</span>
              <input type="text" required
               onChange={e => setLastName(e.target.value)}
               value={lastName}
              />
              {lastNameError && <div className='error-container'>{lastNameError}</div>}
            </label>
            <label>
              <span>Email</span>
              <input type="email" required
               onChange={e => setEmail(e.target.value)}
               value={email}
              />
              {emailError && <div className='error-container'>{emailError}</div>}
            </label>
            <label>
              <span>Phone number</span>
              <input type="number" required
               onChange={e => setPhone(e.target.value)}
               value={phone}
              />
              {phoneError && <div className='error-container'>{phoneError}</div>}
            </label>
            <label>
              <span>Address</span>
              <input type="text" required
               onChange={e => setAddress(e.target.value)}
               value={Address}
              />
              {AddressError && <div className='error-container'>{AddressError}</div>}
            </label>
            <label>
              <span>Country</span>
              <Select options={options} value={country} onChange={changeHandler}/>
              {/* <input type="password" required 
               onChange={e => setPassword(e.target.value)}
               value={password}
               ref={passwordInput}
              /> */}
              {countryError && <div className='error-container'>{countryError}</div>}
            </label>
            <label>
              <span>Password</span>
              <input type="password" required 
               onChange={e => setPassword(e.target.value)}
               value={password}
               ref={passwordInput}
              />
              {passwordError && <div className='error-container'>{passwordError}</div>}
            </label>
            <label>
              <span>Password confirm</span>
              <input type="password" required 
               onChange={e => setPasswordConfirm(e.target.value)}
               value={passwordConfirm}
               ref={passwordConfirmInput}
              />
              {passwordConfirmError && <div className='error-container'>{passwordConfirmError}</div>}
            </label>
          </div>
        </div>
        <div className="auth-show">
          <input type="checkbox" onChange={() => hidePassword()}/>
          <p>Show password</p>
        </div>
        <div className="auth-sub-text">
          <span>Don't have an account? <Link to='/login'>Login</Link></span>
        </div>
        <div className="auth-button-container">
          {!loading && <button onClick={() => handleSignup()}>Signup</button>}
          {loading && <button>Loading...</button>}
        </div>
      </div>
    </div>
  )
}
