import { NavLink, Link } from 'react-router-dom'
// react icons
import { FaUserCircle } from 'react-icons/fa'
import  { FiSettings, FiLogOut } from 'react-icons/fi'
import { LuLayoutDashboard } from 'react-icons/lu'
import { GoNote } from 'react-icons/go'
import { RiSteeringFill } from 'react-icons/ri'
// framer motion
import { motion } from 'framer-motion'
// logout function
import { useLogout } from '../../hooks/useLogout'

export default function AdminMobileSidebar({ handleClick }) {
    const { logout } = useLogout()
  return (
    <div
     className='mobile-sidebar-container'
     onClick={() => handleClick()}
     >
          <motion.div 
          className='mobile-sidebar-component-container'
          initial={{ x:'-100vw' }}
          animate={{ x:0, transition:{
            duration: 0.5,
            type: 'tween'
          } }}
          >
      <div className='sidebar-component-wrapper'>
        <div className='logo-container sidebar-logo-container'>
            <Link to='/' className='link'>
                <h3>ElonCryptoFx</h3>
            </Link>
            </div>
        <div className='sidebar-menu-list-container'>
            <div className='sidebar-menu-list-wrapper'>
                <div className='sidebar-menu-list1'>
                    <li className=''>
                    <NavLink to='/admin/dashboard' className='link sidebar-list-item'>
                        <LuLayoutDashboard className='sidebar-icon'/>
                        <span>Dashboard</span>
                    </NavLink>
                    </li>
                    <li className=''> 
                    <NavLink to='/admin/Users' className='link sidebar-list-item'>
                        <GoNote className='sidebar-icon'/>
                        <span>Users</span>
                    </NavLink> 
                    </li>
                    {/* <li className=''>
                        <NavLink to='/admin/transaction' className='link sidebar-list-item'>
                        <FaUserCircle className='sidebar-icon'/>
                        <span>Transaction</span>
                        </NavLink> 
                    </li>
                    <li className=''>
                        <NavLink to='/admin/history' className='link sidebar-list-item'>
                        <FaUserCircle className='sidebar-icon'/>
                        <span>Deposit</span>
                        </NavLink> 
                    </li> */}
                </div>
            </div>
        </div>
        <div className='sidebar-menu-list2-container'>
            <div className='sidebar-menu-list-wrapper'>
                <div className='sidebar-menu-list2'>                  
                     <li className='sidebar-list-item logout-button' 
                     onClick={() => logout()}
                    > 
                        <FiLogOut className='sidebar-icon'/>
                        <span>LogOut</span>
                    </li>         
                    {/* {isLoading && <li className='sidebar-list-item logout-button' 
                    > 
                        <span>LogOut...</span>
                    </li>} */}                 
                </div>
            </div>
        </div>
      </div>
    </motion.div>
    </div>
  )
}
