import { useState } from 'react'
import './styles.css'
// import logo from '../../assets/images/logo.png'
// side bar components
import SidebarComponent from '../sidebar'
import MobileSidebar from '../mobilesidebar'
import { Link } from 'react-router-dom'
// React icon
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaUserCircle } from 'react-icons/fa'
import { useAuthContext } from '../../hooks/useAuthcontext'
// Auth Context
// import { useAuthContext } from '../../hooks/useAuthcontext.js'


export default function NavbarComponent() {

    const [ showMobileSidebar, setShowMobileSidebar ] = useState(false)
    const { user } = useAuthContext()
    const name = user.displayName.split(' ')[0]
    const handleMobileSidebar = () => {
        showMobileSidebar ? setShowMobileSidebar(false) : setShowMobileSidebar(true)
    }


  return (
    <div className='navbarComponent-container'>
        <div className='navbarComponent-wrapper'>
            <div className='Logo-container'>
                <div className='logo-image-container'>
                    <Link to='/user/dashboard' className='link'>
                        <h3>ElonCryptoFX</h3>
                    </Link>
                </div>
            </div>
            <div className='sidebar-user-information-container navbar-user-information-container'>
            <div className='sidebar-user-details'>
                <span className='sidebar-user-name'>
                    {/* <span className='welcome-note'>Hello </span>John</span> */}
                    <span className='welcome-note'>Hello </span>{name}</span>
            </div>
            <div className='sidebar-user-photo'>
                <FaUserCircle className='sidebar-user-image-icon'/>
            </div>
        </div>
            <div className='navbar-icon'>
                <GiHamburgerMenu 
                  className='navbar-hamburger-icon'
                  onClick={() => handleMobileSidebar()}
                />
            </div>
        </div>
        <SidebarComponent/>
        { showMobileSidebar && <MobileSidebar handleClick={handleMobileSidebar}/>}
    </div>
  )
}
