import './styles.css'
import AdminUsersComponent from './components'
import { UsegetAllUsers } from '../../../hooks/useGetAllUsers'

export default function AdminUsers() {

  const { getUsers, users, loading } = UsegetAllUsers()

  return (
    <div className='admin-user-container'>
        <div className="admin-user-wrapper">
            <AdminUsersComponent result={users} getData={getUsers} loading={loading} />
        </div>
    </div>
  )
}
