import { useState } from "react"
import { auth, database } from "../firebase/config"
import { useFireStore } from "./useFireStore"
import { useAuthContext } from "./useAuthcontext"

export const UseSignup = () => {

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState('')
    const [ success, setSuccess ] = useState(false)
    const [ pin, setPin ] = useState(Math.floor(Math.random()*10000))
    const { dispatch } = useAuthContext()
    const { addDocument } = useFireStore('Users')
    const date = new Date();
    let day = date.getDate();
    let year = date.getFullYear();
    let month = date.getMonth() + 1

    const signup = async(
        firstName,
        lastName,
        phone,
        Address,
        country,
        email,
        password,
        passwordConfirm
        ) => {
        try{
            console.log(email)
            setLoading(true)
            const response = await auth.createUserWithEmailAndPassword(email, password)
            console.log(response)
            if(!response){
                setLoading(false)
                throw new Error('Could  not create user, something went wrong')
            } else{
                setLoading(false)
                await response.user.updateProfile({ displayName: `${firstName} ${lastName}`, phoneNumber: phone })
                await addDocument({
                    firstName,
                    lastName,
                    Address,
                    phone,
                    country,
                    password,
                    disabled: false,
                    withdrawalFee: 200,
                    withdrawalPin: pin,
                    user: response.user.uid,
                    slot: 0,
                    role: 'user',
                    date: `${day}-${month}-${year}`,
                    email
                })
                await database.child(`User/${response.user.uid}`).push({
                    firstName,
                    lastName,
                    Address,
                    phone,
                    country,
                    password,
                    disabled: false,
                    withdrawalFee: 200,
                    withdrawalPin: pin,
                    user: response.user.uid,
                    slot: 0,
                    role: 'user',
                    date: `${day}-${month}-${year}`,
                    email
                },
                (error) => {
                    if(error){
                        setLoading(false)
                        throw new Error('Something went wrong while saving user date')
                    } else{
                        console.log('Success')
                        dispatch({ type: 'LOGIN', payload: response.user })
                    }
                })
            }
            setSuccess(true)
            setLoading(false)
        } catch(error){
            setLoading(false)
            console.log('Something went wrong')
            setError(error.message)
            console.log(error.message)
        }
    }

    return { signup, loading, error, success }
}