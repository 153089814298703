import React, { useEffect, useState } from 'react'
import { UseGetAdminUser } from '../../../../../hooks/useGetAdminUser'
import { UseApproveDeposit } from '../../../../../hooks/useApproveDeposit'
import { useParams, Link } from 'react-router-dom'
import { PreLoader1 } from '../../../../../utils/preloader/preloader1'
import { Success } from '../../../../../utils/marker/success'
import { ErrorMarker } from '../../../../../utils/marker/error'
import './styles.css'

export default function AdminUser() {
    const { getUser, loading, error, success, updateSlot, blockUser, unBlockUser, user, deposits, totalDeposit, updateWithdrawalFee } = UseGetAdminUser()
    const [ slot, setSlot ] = useState(user.slot)
    const [ slotError, setSlotError ] = useState('')
    const [ withdrawalFee, setWithdrawalFee ] = useState(0) 
    const [ withdrawalFeeError, setWithdrawalFeeError ] = useState('') 
    const { id } = useParams()
    const { approveDeposit, approveLoading, approveSuccess, approveError } = UseApproveDeposit()

    useEffect(() => {
        getUser(id)
    },[])

    const handleWithdrawalFee = () => {
        if(!withdrawalFee){
            return setWithdrawalFeeError('Input Fee')
        }
        if(withdrawalFee){
            updateWithdrawalFee(id, withdrawalFee, user)
        }
    }

    const handleUpdateSlot = () => {
        if(!slot){
            return setSlotError('Input slot')
        }
        if(slot){
            console.log(user)
            updateSlot(id,slot, user)
        }
    }

  return (
    <div className='page admin-user-container'>
      {!loading && <div className="admin-user-wrapper">
        <div className="user-detail">
            <div>
                <h3>{user.firstName} {user.lastName}</h3>
            </div>
        </div>
        <div className="admin-user-button-container">
            {user.disabled ? <button onClick={() => unBlockUser(id, user)}>UnBlock</button>: <button onClick={() => blockUser(id, user)}>Block</button>}
            <Link to={`/admin/mail/${id}`}>
                <button>Mail</button>
            </Link>
        </div>
        <div className="admin-user-details-box">
            <div className="admin-user-account-balance">
                <h3>Account Balance</h3>
            </div>
            <hr />
            <div className="admin-user-account-balance">
                <span>Account balance</span>
                <span>${totalDeposit + (+user.slot)}</span>
            </div>
            <hr />
            <div className="admin-user-account-balance">
                <span>Booking</span>
                <span>${user.slot}.00</span>
            </div>
            <hr />
            <div className="admin-user-account-balance">
                <span>Withdraws</span>
                <span>$0.00</span>
            </div>
        </div>
        <div className="admin-user-booking">
            <input type="number"
             className="admin-user-input" 
             placeholder='Add Booking'
             onChange={e => setSlot(e.target.value)}
             value={slot}
            />
            <button className="admin-user-input-button"
                onClick={() => handleUpdateSlot()}
            >Add</button>
        </div>
        <div className="admin-user-booking">
            <input type="number" 
                className="admin-user-input" 
                placeholder='Add Withdrawal Fee'
                onChange={e => setWithdrawalFee(e.target.value)}
                value={withdrawalFee}
            />
            <button 
                className="admin-user-input-button" 
                onClick={() => handleWithdrawalFee()}>Add</button>
        </div>
        
        <div className="admin-user-deposit-log">
            <h3>Deposits</h3>
            <div className="admin-user-deposit-table">
                <table>
                    <thead>
                        <tr>

                            <th>Amount</th>
                            <th>Payment mode</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { (deposits.length !== 0) && deposits.map((deposit) => (
                            <tr key={deposit.id}>
                                <td>${deposit.amount}</td>
                                <td>{deposit.walletType}</td>
                                <td>{deposit.status}</td>
                                <td>{deposit.date}</td>
                                <td>
                                    {
                                        deposit.status === 'Pending' ?
                                        <button 
                                         onClick={() => approveDeposit(deposit.id)}
                                        >Approve</button>:
                                        ''
                                    }
                                </td>
                            </tr>
                        ))
                        }
                        { (!deposits.length === 0) && <tr>No Deposit Found</tr> }
                    </tbody>
                </table>
            </div>
        </div>
      </div>}
      { loading && <PreLoader1/>}
      { approveLoading && <PreLoader1/> }
      { approveSuccess && <Success text='Deposit Approved'/> }
      { approveError && <ErrorMarker text='Could not Approve Deposit'/> }
      { success && <Success/> }
      { slotError && <ErrorMarker text={slotError}/> }
      { withdrawalFeeError && <ErrorMarker text={slotError}/> }
    </div>
  )
}
