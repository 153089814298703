import './styles.css'
import '../success/styles.css'
import { motion } from 'framer-motion'
import { useState } from 'react'

export const ErrorMarker = ({ text }) => {

    const [ show, setShow ] = useState(true)

    const handleHideContainer = () => {
        setShow(false)
    }

    return(
        <>
            { show &&  <div className="marker-container page">
                <motion.div className="marker-wrapper"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                        type: 'spring',
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <div className='marker-icon-container'>
                        <div className="marker-icon-min-container">
                            <div>
                                {/* <VscError className='marker-icon-error'/> */}
                                <h1 className='marker-icon-error'>ERROR</h1>
                            </div>
                            <h4>Some Thing went wrong</h4>
                        </div>
                        <div className="marker-button-container-error">
                            <button 
                             onClick={() => handleHideContainer()}
                            >OK</button>
                        </div>
                    </div>
                </motion.div>
            </div>}
        </>
    )
}