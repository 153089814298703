import './styles.css'
import { useEffect, useState } from 'react'
import { UseMail } from '../../../hooks/useMail'
import { Success } from '../../../utils/marker/success'
import { ErrorMarker } from '../../../utils/marker/error'
import { PreLoader1 } from '../../../utils/preloader/preloader1'
import { UseGetAdminUser } from '../../../hooks/useGetAdminUser'
import { useNavigate, useParams } from 'react-router-dom'
import { database } from '../../../firebase/config'

export default function AdminMail() {

  const [ subject, setSubject ] = useState('')
  const [ subjectError, setSubjectError ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ messageError, setMessageError ] = useState('')
  const [ name, setName ] = useState('')
  const { mail, loading, error, success } = UseMail()
  const [ email, setEmail ] = useState('')
  const { id } = useParams()

  useEffect(() => {
    const getData = async() => {
      try{
        console.log(id)
          await database.child(`User/${id}`).on('value', snapshot => {
              if(snapshot.val() !== null){
                  const data = ({...snapshot.val()})
                  Object.keys(data).map(id => {
                    setName(data[id].firstName)
                      return setEmail(data[id].email)
                  })
              } else {
                  console.log('something went wrong')
              }
          })
      } catch(error){
          console.log(error)
      }
  }
  getData()
  }, [])

  const handleMail = () => {
    console.log(email)
    if(!subject) return setSubjectError('Input Subject')
    if(!message) return setMessage('Enter Message')
    if(subject && message){
        mail(email,subject, message)
        console.log('Hello There2')
    }
  }

  return (
    <div className='page admin-mail-container'>
      <div className="admin-mail-wrapper">
        <div className="mail-text">
            <h3>Mail {name}</h3>
        </div>
        <div className="mail-main-container">
            <div className="mail-subject-input">
                <input type="text" placeholder='mail Subject'
                 onChange={e => setSubject(e.target.value)}
                />
                { subjectError && <div className='error-container'>{subjectError}</div> }
            </div>
            <div className="mail-subject-textarea">
                <textarea name="" id="" cols="30" rows="27" placeholder='Enter mail message' onChange={e => setMessage(e.target.value)}></textarea>
                { messageError && <div className='error-container'>{messageError}</div> }
            </div>
            <div className="mail-button-container">
                <button onClick={() => handleMail()}>Send Mail</button>
            </div>
        </div>
      </div>
      {loading && <PreLoader1/>}
      { error && <ErrorMarker text='something went wrong'/> }
      { success && <Success text='Email Sent'/> }
    </div>
  )
}
