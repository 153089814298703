import './styles.css'
import Select from 'react-select'
import { useState } from 'react'
import Bitcoin from '../../../assets/Btc.png'
import ETh from '../../../assets/Eth.png'
import Binance from '../../../assets/BNB.png'
import Usdt from '../../../assets/Usdt.png'

import Fund from './components'

export default function FundAccount (){
    const [ walletType, setWalletType ] = useState('')
    const [ amount, setAmount ] = useState('')
    const [ Error, setError ] = useState('') 
    const [ showStepOne, setShowStepOne ] = useState(false)
    const [ walletAddress, setWalletAddress ] = useState('')
    const [ network, setNetwork ] = useState('')

    const handleNext = () => {
        setError('')
        if(!amount) return setError('Input amount for investment')
        if(!walletType) return setError('Select a wallet type')
        if(amount && walletType){
            setShowStepOne(true)
        }
    }

    const handleBtc = () => {
        setWalletAddress('17iYbvyVuP8M43ACNtVcPvcCu2oJ7UFn3q')
        setWalletType('Bitcoin')
        setNetwork('btc')
    }
    const handleETh = () => {
        setWalletAddress('0x6cb1c82b91691a0e4ff264ef6a60902c195f5b73')
        setWalletType('Ethereum')
        setNetwork('Erc20')
    }
    const handleBnb = () => {
        setWalletAddress('0x6cb1c82b91691a0e4ff264ef6a60902c195f5b73')
        setWalletType('Binance')
        setNetwork('BEP20')
    }
    const handleUsdt = () => {
        setWalletAddress('TSA2PLScgbuQW68ytTYKRUb2QRKLAHEac7')
        setWalletType('USDT')
        setNetwork('Trc20')
    }

    return (
        <>
            {!showStepOne && <div className="fund-account-container page">
                <div className="fund-account-wrapper wrapper">
                    <div className="fund-account-text">
                        <h2>Fund your account</h2>
                        <span>Fund your account to start earning</span>
                    </div>
                    {Error && <div className="main-fund-error-container">
                        <div className="error-container error">
                            {Error}
                        </div>
                    </div>}
                    <div className="fund-account-main">
                        <div className="fund-account-main-wrapper">
                            <div className="input-container">
                                <label>
                                    <input type="number" placeholder='Enter Amount'
                                     onChange={e => setAmount(e.target.value)}
                                     value={amount}
                                    />
                                </label>
                            </div>
                            <div className="payment-wallet-type">
                                <div className="payment-wallet-type-box"
                                 onClick={() => handleBtc()}
                                >
                                    <div className="payment-wallet-image">
                                        <img src={Bitcoin} alt='test' />
                                    </div>
                                    <div className="payment-wallet-text">
                                        <h4>Bitcoin</h4>
                                    </div>
                                </div>
                                <div className="payment-wallet-type-box"
                                 onClick={() => handleETh()}
                                >
                                    <div className="payment-wallet-image">
                                        <img src={ETh} alt='test' />
                                    </div>
                                    <div className="payment-wallet-text">
                                        <h4>Ethereum</h4>
                                    </div>
                                </div>
                                <div className="payment-wallet-type-box"
                                 onClick={() => handleBnb()}
                                >
                                    <div className="payment-wallet-image">
                                        <img src={Binance} alt='test' />
                                    </div>
                                    <div className="payment-wallet-text">
                                        <h4>Binance</h4>
                                    </div>
                                </div>
                                <div 
                                 onClick={() => handleUsdt()}
                                 className="payment-wallet-type-box">
                                    <div className="payment-wallet-image">
                                        <img src={Usdt} alt='test' />
                                    </div>
                                    <div className="payment-wallet-text">
                                        <h4>USDT</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="fund-account-button-container">
                                <button onClick={() => handleNext()}>Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {showStepOne && <Fund
             amount={amount} 
             walletAddress={walletAddress}
             network={network}
             walletType={walletType}
            />}
        </>
    )
}