import './styles.css'
import { useState } from 'react'
import { GoCopy } from 'react-icons/go'
import QRCode from 'qrcode.react'
import { useFundAccount } from '../../../../hooks/useFundAccount'
import { PreLoader1 } from '../../../../utils/preloader/preloader1'
import { Success } from '../../../../utils/marker/success'

export default function Fund ({ amount, walletAddress, network, walletType }){

    const [ copied, setCopied ] = useState(false)
    const [ selectedImage ,setSelectedImage ] = useState('')
    const [ error, setError ] = useState('')
    const { loading, fundError, fundAccount, success } = useFundAccount()

    const handleCopy = async()=> {
        try{
            let text = walletAddress
            navigator.clipboard.writeText(text)
            setCopied(true)
            setTimeout(() => setCopied(false), 3000)
        } catch(error){
            console.log(error)
        }
    } 
      
        const handleImageSelected = (e) => {
          const file = e.target.files[0];
          if (file) {
            setSelectedImage(file);
          }
        };

        const handleFund = () => {
            setError('')
            if(!selectedImage){
                setError('Please upload payment proof')
            }else{
                fundAccount(amount, walletType)
            }
        }

    return (
        <div className='page fund-container'>
            {!loading && <div className="fund-wrapper">
                <div className="fund-digit">
                    <h2>${amount}</h2>
                    <p className='gray'>Complete your transaction</p>
                </div>
                <div className="fund-sub-section">
                    <p className="fund-sub-section-text">
                    you are to make payment of <b>${amount}</b> using your selected payment method. Screenshot and upload the proof of payment
                    </p>
                    <p>Payment Method: <b>{walletType}</b></p>
                    <span>Network types:<b>{network}</b></span>
                    <div className="barcode-container">
                        <QRCode size={250} value={walletAddress}/>
                    </div>
                    {copied && <div style={{ color: 'green' }}>Copied!</div>}
                    <div className="fund-sub-section-wallet">
                        <span>{walletAddress}</span>
                        <div className="fund-icon-con" 
                         onClick={() => handleCopy()}
                        >
                            <GoCopy/>
                        </div>
                    </div>
                </div>
                <div className="payment-proof">
                    <p className='gray'>Upload payment proof after payment to complete transaction</p>
                    <div className="payment-proof-con">
                        <input
                         type="file" 
                         accept='image/*' 
                         className='payment-proof-input'
                         onChange={e => handleImageSelected(e)}
                        />
                    </div>
                </div>
                { error && <div className='error'>{error}</div> }
                <div className="payment-button">
                    <button onClick={() => handleFund()}>Submit Payment</button>
                </div>
            </div>}
            { loading && <PreLoader1/> }
            {success && <Success text='Deposit successful'/> }
        </div>
    )
}