import firebase from "firebase/app";
import 'firebase/database'
import 'firebase/auth'
import 'firebase/firestore'
// import 'firebase/'


const firebaseConfig = {
  apiKey: "AIzaSyCaD12YbqrvNrQZYxLdV6nLlNar8--vTs0",
  authDomain: "eloncryptofx-a7435.firebaseapp.com",
  projectId: "eloncryptofx-a7435",
  storageBucket: "eloncryptofx-a7435.appspot.com",
  messagingSenderId: "195430108738",
  appId: "1:195430108738:web:296bd204d8d13b3e0c27db"
};

firebase.initializeApp(firebaseConfig)

const database = firebase.database().ref()
const firestore = firebase.firestore()
const timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()


export { database, auth, firestore, timestamp }