import './styles.css'
import { useEffect, useState } from 'react'
import { VscCircleLargeFilled } from 'react-icons/vsc'
import Btc from '../../../assets/Btc.png'
import Eth from '../../../assets/Eth.png'
import Bnb from '../../../assets/BNB.png'
import Usdt from '../../../assets/Usdt.png'
import { UsegetBalance } from '../../../hooks/usegetBalance'
import { Success } from '../../../utils/marker/success'
import { ErrorMarker } from '../../../utils/marker/error'

export default function Withdraw() {
    const [ amount, setAmount ] = useState('')
    const [ wallet, setWallet ] = useState('')
    const [ walletType, setWalletType ] = useState('') 
    const [error, setError ] = useState('')
    const [ errorMarker, setErrorMarker ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const { slot, deposit, getBalance, withdrawalFee } = UsegetBalance()

    useEffect(() => {
        getBalance()
    }, [])
    
    const handleWithdraw = () => {
        setError('')
        if(!amount) return setError('Input an amount to withdraw')
        if(!wallet) return setError('add Your wallet address')
        if(!walletType) return setError('Select a wallet')
        
        if(amount && wallet && walletType){
            const balance = deposit + slot
            if(amount > balance){
                setError('Insufficient balance')
                setErrorMarker(true)
            } else if(amount <= 0){
                setError('Enter a valid amount')
            } else{
                setSuccess(true)
            }
        }
    }

  return (
    <div className='page withdraw-container'>
      <div className="withdraw-wrapper">
        <div className="withdraw-main-text">
            <h2>Request For Withdrawal</h2>
        </div>
        {error && <div className="error-container">{error}</div>}
        <div className="withdraw-main-section">
            <div className="withdrawal-fee">
                <h3>Fee: ${withdrawalFee}.00</h3>
            </div>
            <div className="withdraw-amount">
                <label>
                    <input type="number" className='amount-input'
                     placeholder='Enter Amount'
                     onChange={e => setAmount(e.target.value)}
                     value={amount}/>
                    <div className="account-balance">
                        <span>Account balance: <b>${deposit + slot}.00</b></span>
                    </div>
                </label>
            </div>
            <div className="withdraw-amount wallet-address">
                <label>
                    <input type="text"
                     className='amount-input' 
                     placeholder='Enter wallet address'
                     onChange={e => setWallet(e.target.value)}
                     value={wallet}
                     />
                </label>
            </div>
            <div className="wallet-type">
                <div className="wallet-type-box"
                 onClick={() => setWalletType('Bitcoin')}
                >
                    <div className="wallet-type-name">
                       <span className='wallet-icon-con'>
                        <VscCircleLargeFilled className='wallet-icon'/>
                       </span>
                       <span className='wallet-name'>Bitcoin</span>
                    </div>
                    <div className="wallet-type-image-con">
                        <img src={Btc} alt='test'/>
                    </div>
                </div>
                <div className="wallet-type-box"
                 onClick={() => setWalletType('Ethereum')}
                >
                    <div className="wallet-type-name">
                       <span className='wallet-icon-con'>
                        <VscCircleLargeFilled className='wallet-icon'/>
                       </span>
                       <span className='wallet-name'>Ethereum</span>
                    </div>
                    <div className="wallet-type-image-con">
                        <img src={Eth} alt='test'/>
                    </div>
                </div>
                <div className="wallet-type-box" 
                onClick={() => setWalletType('USDT')}
                >
                    <div className="wallet-type-name">
                       <span className='wallet-icon-con'>
                        <VscCircleLargeFilled className='wallet-icon'/>
                       </span>
                       <span className='wallet-name'>USDT</span>
                    </div>
                    <div className="wallet-type-image-con">
                        <img src={Usdt} alt='test'/>
                    </div>
                </div>
                <div className="wallet-type-box" 
                 onClick={() => setWalletType('Binance')}
                >
                    <div className="wallet-type-name">
                       <span className='wallet-icon-con'>
                        <VscCircleLargeFilled className='wallet-icon'/>
                       </span>
                       <span className='wallet-name'>Binance</span>
                    </div>
                    <div className="wallet-type-image-con">
                        <img src={Bnb} alt='test'/>
                    </div>
                </div>
            </div>
            <div className="withdraw-button-con">
                <button onClick={() => handleWithdraw()}>Request withdrawal</button>
            </div>
        </div>
      </div>
      { errorMarker && <ErrorMarker text={error}/> }
      { success && <Success text="Make sure you've deposited the withdrawal fee" /> }
    </div>
  )
}
