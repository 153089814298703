import { useState } from "react"
import { auth } from "../firebase/config"
import { useAuthContext } from "./useAuthcontext"

export const UseAdminLogin = () => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState('')
    const { dispatch  } = useAuthContext()
    // const [ success ]

    const login = async(email, password) => {
        try{
            setError('')
            setLoading(true)
            const response = await auth.signInWithEmailAndPassword(email, password)
            if(!response){
                throw new Error('Something went wrong')
            } else{
                dispatch({ type: 'LOGIN', payload: response.user })

                /* database.child(`User/${response.user.uid}`).on( 'value' ,snapshot => {
                    if(snapshot.val() !== null){
                        setLoading(false)
                    }else {
                        console.log('Could not get Data')
                        throw new Error('Could not get Data')
                    }
                }) */
            }
            setLoading(false)
        } catch(error){
            setLoading(false)
            console.log(error)
            setError(error.message)
        }
    }
    return { login, loading, error }
}