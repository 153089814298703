import { firestore, database } from "../firebase/config";
import { useState } from "react";

export const UseGetAdminUser = () => {

    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ user, setUser ] = useState({})
    const [ deposits, setDeposits ] = useState([])
    const [ totalDeposit, setTotalDeposit ] = useState(0)

    const getUser = async (id)  => {
        try{
            setLoading(true)
            setError(false)
            const query = [ 'user', "==", id ]
            /* let ref = await firestore.collection('Users').where(...query)
            ref.onSnapshot((snapshot) => {
                let result = []
                snapshot.docs.foreach((doc) => {
                    result.push({...doc.data(), id:doc.id })
                    console.log({...doc.data(), id:doc.id })
                    setUser({...doc.data(), id:doc.id })
                })
            }) */

            console.log(id)
            let tUser = []
            await database.child(`User/${id}`).on('value', snapshot => {
                if(snapshot.val() !== null){
                    const data = ({...snapshot.val()})
                    Object.keys(data).map(uid => {
                        console.log(uid)
                        return setUser({...data[uid], uid})
                    })
                } else {
                    console.log('something went wrong')
                }
            })

            let Dref = await firestore.collection('Deposits').where(...query)
            await Dref.onSnapshot((snapshot) => {
                let result = []
                snapshot.docs.forEach((doc) => {
                    result.push({ ...doc.data(), id: doc.id })
                })
                console.log(result)
                const mainResult = result.filter(el => el.status === 'Approved')
                if(Array.isArray(mainResult) && mainResult.length !== 0){
                    const dt = mainResult.map(trans => +(trans.amount))
                    .reduce((acct, amount) => ((acct += amount)))
                    setTotalDeposit(dt)
                }
            })

            let DepositRef = await firestore.collection('Deposits').where(...query)
            DepositRef.onSnapshot(snapshot => {
                let result = []
                snapshot.docs.forEach(doc => {
                    result.push({...doc.data(), id: doc.id})
                    setDeposits(result)
                })
            })

            setError(false)
            setLoading(false)
        } catch(error){
            setError(true)
            setLoading(false)
            console.log(error)
        }       
    }

    const updateSlot = async (id, slot, Uid) => {
        try{
            console.log('slot error')
            setLoading(true)
            setSuccess(false)
            setError(false)
            console.log(id, slot, Uid.uid)

            database.child(`User/${id}/${Uid.uid}`).update({slot: slot}, err => {
                if(err){
                    setLoading(false)
                    setError(true)
                    console.log(err)
                    setSuccess(false)
                } else{
                    firestore.collection('Users').doc(id).update({slot: slot})
                }
            })

            setSuccess(true)
            setLoading(false)
            setError(false)
        } catch(error){
            setSuccess(false)
            setLoading(false)
            setError(true)
            console.log(error)
        }
    }



    const blockUser = async (id, Uid) => {
        try{
            setLoading(true)
            setSuccess(false)
            setError(false)
            console.log(id, Uid)
            database.child(`User/${id}/${Uid.uid}`).update({ disabled: true }, err => {
                if(err){
                    setLoading(false)
                    setError(true)
                    console.log(err)
                    setSuccess(false)
                } else{
                    firestore.collection('Users').doc(id).update({disabled: true})
                }
            })

            setSuccess(true)
            setLoading(false)
            setError(false)
        } catch(error){
            setSuccess(false)
            setLoading(false)
            setError(true)
            console.log(error)
        }
    }

    const unBlockUser = async (id, Uid) => {
        try{
            setLoading(true)
            setSuccess(false)
            setError(false)

            database.child(`User/${id}/${Uid.uid}`).update({disabled: false}, err => {
                if(err){
                    setLoading(false)
                    setError(true)
                    console.log(err)
                    setSuccess(false)
                } else{
                    firestore.collection('Users').doc(id).update({disabled: false})
                }
            })

            setSuccess(true)
            setLoading(false)
            setError(false)
        } catch(error){
            setSuccess(false)
            setLoading(false)
            setError(true)
            console.log(error)
        }
    }

    const updateWithdrawalFee = async (id, fee,Uid) => {
        try{
            setLoading(true)
            setSuccess(false)
            setError(false)

            database.child(`User/${id}/${Uid.uid}`).update({withdrawalFee: fee}, err => {
                if(err){
                    setLoading(false)
                    setError(true)
                    console.log(err)
                    setSuccess(false)
                } else{
                    firestore.collection('Users').doc(id).update({withdrawalFee: fee})
                }
            })

            setSuccess(true)
            setLoading(false)
            setError(false)
        } catch(error){
            setSuccess(false)
            setLoading(false)
            setError(true)
            console.log(error)
        }
    }


    return { loading, success, error, getUser, updateSlot, blockUser, unBlockUser, user, deposits, totalDeposit, updateWithdrawalFee }
}