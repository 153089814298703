import { database, firestore } from "../firebase/config"
import { useAuthContext } from "./useAuthcontext"
import { useState } from "react"
import { useCollection } from "./useCollection"
// import { ref } from 'firebase/database'
// import {  }

export const UsegetBalance = () => {
    const { user } = useAuthContext()
    const [ slot, setSlot ] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const [ balance, setBalance ] = useState(0)
    const [ deposit, setDeposit ] = useState(0)
    const [ withdrawalFee, setWithdrawalFee ] = useState(0)
    let dt = 0


    const getBalance = async () => {
        try{
            setLoading(true)
            const query = [ 'user', "==", user.uid ]
            let ref = await firestore.collection('Deposits').where(...query)
            await ref.onSnapshot((snapshot) => {
                let result = []
                snapshot.docs.forEach((doc) => {
                    result.push({ ...doc.data(), id: doc.id })
                })
                console.log(result)
                const mainResult = result.filter(el => el.status === 'Approved')
                if(Array.isArray(mainResult) && mainResult.length !== 0){
                    const dt = mainResult.map(trans => +(trans.amount))
                    .reduce((acct, amount) => ((acct += amount)))
                    setDeposit(dt)
                }
            })

            await database.child(`User/${user.uid}`).on('value', snapshot => {
                if(snapshot.val() !== null){
                    const data = ({...snapshot.val()})
                    Object.keys(data).map(id => {
                        return setSlot(+(data[id].slot))
                    })
                    Object.keys(data).map(id => {
                        return setWithdrawalFee(+(data[id].withdrawalFee))
                    })
                } else {
                    console.log('something went wrong')
                }
            })
            setLoading(false)
        } catch(error){
            setLoading(false)
            console.log(error)
        }
    }
    return { getBalance, slot, loading, balance, deposit, dt, withdrawalFee }
}