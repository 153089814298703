import { Link } from 'react-router-dom'
import './styles.css'
// import { FaBars } from 'react-icons/fa'
// import Mobilemenu from './Mobilemenu'
// import { useState, useEffect } from 'react'

export default function GeneralNavbar() {
  return (
    <div className="Navbar">
      <nav>
        <Link to='/' className='link'>
        <div className="logo">
            <h2>ElonCryptoFx</h2>
        </div>
        </Link>
        <div className='auth list menu'>
            <li>
              <Link to='/sign_up' className='link sign_up'>Get started</Link>
              </li>
        </div>
      </nav>
    </div>
  )
}
