import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useRef } from 'react'
import './styles.css'
import { UseLogin } from '../../../hooks/useLogin'

export default function Login() {
  const [ email, setEmail ] = useState('')
  const [ emailError, setEmailError ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ passwordError, setPasswordError ] = useState('')
  const { login, loading, error } = UseLogin()


  const passwordInput = useRef()

  const hidePassword = () => {
    passwordInput.current.type = 'text'
    // if(passwordInput.current.type = 'text'){
    //   passwordInput.current.type = 'password'
    // } else{
    // }
  }

  const handleLogin = () => {
    setEmailError('')
    setPasswordError('')
    if(!email) return setEmailError('Input email')
    if(!password) return setPasswordError('Input password')
    if(email && password){
      login(email, password)
    }
  }

  return (
    <div className='auth-container'>
      <div className="auth-wrapper">
        <div className="auth-text">
          <h2>Login</h2>
        </div>
        <div className="auth-form-container">
          {error && <div className='error-container error-center'>{error}</div>}
          <div className="auth-form-sub">
            <label>
              <span>Email</span>
              <input type="email" required
               onChange={e => setEmail(e.target.value)}
               value={email}
              />
              {emailError && <div className='error-container'>{emailError}</div>}
            </label>
            <label>
              <span>Password</span>
              <input type="password" required 
               onChange={e => setPassword(e.target.value)}
               value={password}
               ref={passwordInput}
              />
              {passwordError && <div className='error-container'>{passwordError}</div>}
            </label>
          </div>
        </div>
        <div className="auth-show">
          <input type="checkbox" onChange={() => hidePassword()}/>
          <p>Show password</p>
        </div>
        <div className="auth-sub-text">
          <span>Don't have an account? <Link to='/sign_up'>Signup</Link></span>
        </div>
        <div className="auth-button-container">
          {!loading && <button onClick={() => handleLogin()}>Login</button>}
          {loading && <button>Loading...</button>}
        </div>
      </div>
    </div>
  )
}
