import { useState } from 'react'
import { firestore } from '../firebase/config'

export const UseApproveDeposit = () => {
    const [ approveLoading, setApproveLoading ] = useState(false)
    const [ approveError, setApproveError ] = useState(false)
    const [ approveSuccess, setApproveSuccess ] = useState(false)

    const approveDeposit = async (id) => {
        try{
            setApproveLoading(true)
            setApproveError(false)
            setApproveSuccess(false)
            firestore.collection('Deposits').doc(id).update({ status: 'Approved' })
            setApproveError(false)
            setApproveLoading(false)
            setApproveSuccess(false)
        } catch(error){
            console.log(error)
            setApproveError(true)
            setApproveLoading(false)
            setApproveSuccess(false)
        }
    }
    return { approveDeposit, approveError, approveLoading, approveSuccess }
}